<script>
import basicInfoService from '@/services/basic/basic-info'

import MenuBar from './components/MenuBar.vue'
import InfoDisplay from './components/InfoDisplay.vue'

export default {
  components: {
    MenuBar,
    InfoDisplay
  },
  data() {
    return {
      errMsg: null,
      isLoading: false,
      company: {}
    }
  },
  created() {
    this.fetchCompanyInfo()
  },
  methods: {
    async fetchCompanyInfo() {
      this.isLoading = true
      try {
        const res = await basicInfoService.getCompanyInfo()
        this.company = res.data.data
      } catch (err) {
        this.errMsg = err.response?.data?.thMessage ?? 'เกิดความผิดพลาดในระบบ'
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="ข้อมูลปูพื้นฐานของกิจการ"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- informatio display -->
    <info-display
      :companyProp="company"
      :isLoadingProp="isLoading"
    ></info-display>

    <!-- error modal -->
    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </b-container>
</template>

<style></style>
