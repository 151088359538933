<script>
import _ from 'lodash'

import Card from '@/components/card/Card.vue'

export default {
  props: {
    companyProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    AppCard: Card
  },
  data() {
    return {
      fields: [
        {
          label: 'รหัสผู้ประกอบการ',
          key: 'id',
          col: 12,
          md: 12
        },
        {
          label: 'ชื่อผู้ประกอบการ',
          key: 'name',
          col: 12,
          md: 12
        },
        {
          label: 'ชื่อสถานประกอบการ',
          key: 'branchName',
          col: 12,
          md: 12
        },
        {
          label: 'เลขประจำตัวผู้เสียภาษี',
          key: 'taxNumber',
          col: 12,
          md: 6
        },
        {
          label: 'รหัสสาขา',
          key: 'branch',
          col: 12,
          md: 6
        },
        {
          label: 'อัตราภาษีมูลค่าเพิ่ม (%)',
          key: 'taxRatio',
          type: 'number',
          cols: 12,
          md: 6
        },
        { label: 'อีเมล', key: 'email', col: 12, md: 6 },
        {
          label: 'เลขที่, ถนน',
          key: 'address1',
          col: 12,
          md: 12
        },
        {
          label: 'ตำบล, อำเภอ',
          key: 'address2',
          col: 12,
          md: 12
        },
        { label: 'จังหวัด', key: 'province', col: 12, md: 6 },
        {
          label: 'รหัสไปรษณีย์',
          key: 'postalCode',
          col: 12,
          md: 6
        },
        {
          label: 'โทรศัพท์',
          key: 'telephoneNumber',
          col: 12,
          md: 6
        },
        { label: 'โทรสาร', key: 'faxNumber', col: 12, md: 6 }
      ],
      company: {}
    }
  },
  watch: {
    companyProp() {
      this.reAssignToCompany()
    }
  },
  created() {
    this.reAssignToCompany()
  },
  filters: {
    specialDisplayFilter(value) {
      const splittedValue = value.split('')
      const dashIndexes = [1, 5, 10, 12]
      let newValue = ''

      splittedValue.forEach((v, i) => {
        if (dashIndexes.includes(i)) newValue += `-${v}`
        else newValue += `${v}`
      })

      return newValue
    }
  },
  methods: {
    reAssignToCompany() {
      if (!_.isEqual(this.company, this.companyProp)) {
        this.company = _.cloneDeep(this.companyProp)
      }
    },
    getValue(field) {
      const { key } = field
      if (this.company && this.company[key]) {
        return this.company[key]
      }
      return '-'
    }
  }
}
</script>

<template>
  <div class="py-3">
    <app-card>
      <div v-if="isLoadingProp">
        <b-row>
          <b-col cols="12" class="text-center">
            <b-spinner class="align-middle mr-3" variant="primary"></b-spinner>
            <strong>กำลังโหลดข้อมูล ...</strong>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col cols="12" class="mb-2">
            <span class="text-lg font-weight-bold">
              ข้อมูลปูพื้นฐานของกิจการ
            </span>
          </b-col>
          <b-col
            v-for="field in fields"
            :key="field.key"
            :cols="field.cols"
            :md="field.md"
            class="mt-3"
          >
            <span class="mr-2 font-weight-bold">{{ field.label }} :</span>
            <span v-if="['taxNumber'].includes(field.key)">
              {{ getValue(field) | specialDisplayFilter }}
            </span>
            <span v-else>{{ getValue(field) }}</span>
          </b-col>
        </b-row>
      </div>
    </app-card>
  </div>
</template>

<style scoped></style>
